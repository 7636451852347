.dropdown-menu {
    top: 102%;
    padding: 0;
}
.dropdown-divider {
    margin: 0;
}
.dropdown span, .dropdown a::after {
    color: #fff;
    margin-left: 7px;
}
.dropdown-item {
    padding: 0.4rem 1.5rem;
}
.dropdown-item:hover {
    background-color: #2c3e50d6;
}
.nav-link {
    padding: .5rem 1rem;
    text-align: right;
}
