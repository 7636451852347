.dropdown-menu {
    top: 102%;
    padding: 0;
}
.dropdown-divider {
    margin: 0;
}
.dropdown span, .dropdown a::after {
    color: #fff;
    margin-left: 7px;
}
.dropdown-item {
    padding: 0.4rem 1.5rem;
}
.dropdown-item:hover {
    background-color: #2c3e50d6;
}
.nav-link {
    padding: .5rem 1rem;
    text-align: right;
}


.home-text {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 25px;
    color: rgba(73, 73, 73, 0.747)
}
.toast {
    position: fixed;    
}
.top-right {
    top: 45px;
    right: 5px;    
}
.top-left {
    top: 45px;
    left: 5px;    
}
.bottom-left {
    bottom: 5px;
    left: 5px;    
}
.bottom-right {
    bottom: 5px;
    right: 5px;
}
.close {
    color: #95a5a6;
}
.close:hover {
    color: #7c7c7c;
}
body {
	height: 100%;
}

.fill { 
    min-height: 100%;
    height: 100%;
}

.pointer {
    cursor: pointer;
}

.app-icon {
    margin-left: 5px;
    margin-right: 5px;
}

.app-heading {
	color: #2c3e50;
}

.app-icon-block {
    margin-left: 5px;
	margin-right: 5px;
	width: 20px;
}

.app-icon-left {
    margin-right: 5px;
}

.app-icon-block-left {
	margin-right: 5px;
	width: 20px;
}

.app-icon-right {
    margin-left: 5px;
}

.app-badge-right {
    margin-left: 10px;
}

.app-card {
	margin-bottom: 15px;
}

.app-nav {
	margin-bottom: 10px;
	cursor: pointer;
}

.btn-toolbar .btn:first-child {
	margin-right: 5px;
	margin-left: 0px;
}

.btn-toolbar .btn:last-child {
	margin-right: 5px;
	margin-left: 0px;
}

.btn-toolbar .btn:not(:first-child):not(:last-child) {
	margin-right: 5px;
	margin-left: 5px;
}

button:disabled {
	cursor: not-allowed !important;
	pointer-events: all !important;
}

.responsive {
    width: 100%;
    height: auto;
}

.breadcrumb {
	background-color: rgba(255, 255, 255, 0);
	padding: 0px 0px;
}

.card-subtitle {
	color: #5a5a5a;
}

.row-spaced {
	margin-bottom: 10px;
}

.delete-badge:hover {
	/*background-color: rgba(231, 76, 60, 0.8);*/
	background-color: #E74C3C;
}

.info-badge:hover {
	/*background-color: rgba(231, 76, 60, 0.8);*/
	background-color: #3498DB;
}

.tight-spaced {
	margin-top: 5px;
	margin-bottom: 5px;
}

.slide-down-fade-in {
	opacity: 1;
	-webkit-animation-name: slideDownFadeIn;
	        animation-name: slideDownFadeIn;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-duration: .7s;
	        animation-duration: .7s;
}

.slide-down {
	opacity: 1;
	-webkit-animation-name: slideDown;
	        animation-name: slideDown;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-duration: .7s;
	        animation-duration: .7s;
}

.slide-up {
	-webkit-animation-name: slideUp;
	        animation-name: slideUp;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-duration: .7s;
	        animation-duration: .7s;
}

.slide-up-fade-out {
	-webkit-animation-name: slideUpFadeOut;
	        animation-name: slideUpFadeOut;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-duration: .7s;
	        animation-duration: .7s;
}

.fade-in {
	opacity: 1;
	-webkit-animation-name: fadeInOpacity;
	        animation-name: fadeInOpacity;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-duration: .75s;
	        animation-duration: .75s;
}

.fade-out {
	opacity: 0;
	-webkit-animation-name: fadeOutOpacity;
	        animation-name: fadeOutOpacity;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-duration: .75s;
	        animation-duration: .75s;
}

.card-header .fa {
	transition: .3s transform ease-in-out;
}

.center-align {
	text-align: center;
}

.left-align {
	text-align: left;
}

.right-align {
	text-align: right;
}

.bold {
	font-weight: bold;
}

a.app-link {
	cursor: pointer;
	color: #18BC9C !important;
}
a.app-link:hover {
	color: #2e2e2e !important;
	text-decoration: none !important;
}

.contact-heading {
	font-size: 1.2em;
	font-weight: bold;
}

ul { 
	-webkit-padding-start: 10px !important; 
	        padding-inline-start: 10px !important; 
}

.hidden {
	display: none;
}

.slide-left-fade-out {
	opacity: 0;
	-webkit-animation-name: slideLeftFadeOut;
	        animation-name: slideLeftFadeOut;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-duration: .5s;
	        animation-duration: .5s;
}

.rbt-close {
	margin-top: 0px !important;
}

.help-icon {
	color: #7b8a8b !important;
	cursor: pointer;
}

.help-icon:hover {
	color: #3498DB !important;
}

.mid-modal-header {
	padding: 10px 1rem 10px 1rem;
}

.dropdown-button-label {
	margin-top: 12px;
	margin-right: 5px;
}

.hover-zoom {
	transition: -webkit-transform;
	transition: transform;
	transition: transform, -webkit-transform
}

.hover-zoom:hover  {
	-webkit-transform: scale(1.3);
	        transform: scale(1.3)
}

.ag-cell-focus,.ag-cell-no-focus{
	border:none !important;
}
.ag-center-cols-container {
    min-width: 100%;
}
  /* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus{
	border:none !important;
	outline: none;
}

@-webkit-keyframes slideLeftFadeOut {
	0% {
		opacity: 1;	
		-webkit-transform: translateX(0%);	
		        transform: translateX(0%);
	}	
	100% {
		opacity: 0;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}		
}

@keyframes slideLeftFadeOut {
	0% {
		opacity: 1;	
		-webkit-transform: translateX(0%);	
		        transform: translateX(0%);
	}	
	100% {
		opacity: 0;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}		
}


@-webkit-keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}


@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

@-webkit-keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}

@-webkit-keyframes slideDownFadeIn {
	0% {
		opacity: 0;	
		-webkit-transform: translateY(-100%);	
		        transform: translateY(-100%);
	}	
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}		
}

@keyframes slideDownFadeIn {
	0% {
		opacity: 0;	
		-webkit-transform: translateY(-100%);	
		        transform: translateY(-100%);
	}	
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}		
}


@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}	
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}		
}


@keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}	
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}		
}

@-webkit-keyframes slideUpFadeOut {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}		
}

@keyframes slideUpFadeOut {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}		
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
	100% {	
		-webkit-transform: translateY(-100%);	
		        transform: translateY(-100%);
	}		
}

@keyframes slideUp {
	0% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
	100% {	
		-webkit-transform: translateY(-100%);	
		        transform: translateY(-100%);
	}		
}
